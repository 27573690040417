import { Component, OnInit } from '@angular/core';

import { DecimalPipe } from '@angular/common';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { environment } from '@environments/environment';
import { IOrderDetailEntity } from '@models/order.model';
import { AppStateService, FormStateService, NotificationService, RequestService } from '@services/index';
import { NgxPaginationModule } from 'ngx-pagination';
@Component({
  selector: 'fh-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DecimalPipe,
    NgxPaginationModule,
    RouterLink
],
})
export class OrderListComponent implements OnInit {
  checked = false;
  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;

  list:any[]  = []; // Replace 'any' with your specific type for the list items
  currentPage = 1;
  itemsPerPage = 10; // Number of items to display per page
  totalRecords!: number;

  filterForm!: UntypedFormGroup;
  filterOptions = [
    { label: 'Customer Name', value: 'customerName' },
    { label: 'Remarks', value: 'remarks' },
    { label: 'Order ID', value: 'orderId' },
  ];
  readonly formInfoForPersistence = {
    name: 'orderList',
  };

  constructor(
    private auth: RequestService,
    private appStateService: AppStateService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private formStateService: FormStateService
  ) {}
  ngOnInit(): void {
    const storedFilterType = this.formStateService.getFormValue(
      this.formInfoForPersistence.name,
      'filterType'
    );
    const storedSearchTerm = this.formStateService.getFormValue(
      this.formInfoForPersistence.name,
      'searchTerm'
    );

    const currentPage = this.formStateService.getFormValue(
      this.formInfoForPersistence.name,
      'currentPage'
    );
    this.currentPage = currentPage || 1;
    this.filterForm = this.formBuilder.group({
      filterType: [storedFilterType || 'customerName'],
      searchTerm: [storedSearchTerm || ''],
    });
    this.filterForm.controls['filterType'].valueChanges.subscribe(() => {
      console.log(
        'value changes triggered',
        this.filterForm.controls['filterType'].value
      );
      this.filterForm.controls['searchTerm'].setValue('');
    });
    this.loadOrders();
  }

  loadOrders(
    options: {
      pageIndex?: number;
      pageSize?: number;
      filterType?: string;
      searchTerm?: string;
    } = {}
  ): void {
    const {
      pageIndex = this.currentPage - 1,
      pageSize = this.itemsPerPage,
      filterType = this.filterForm.controls['filterType'].value,
      searchTerm = this.filterForm.controls['searchTerm'].value,
    } = options;

    this.formStateService.setFormValue(
      this.formInfoForPersistence.name,
      'filterType',
      filterType
    );
    this.formStateService.setFormValue(
      this.formInfoForPersistence.name,
      'searchTerm',
      searchTerm
    );
    this.formStateService.setFormValue(
      this.formInfoForPersistence.name,
      'currentPage',
      this.currentPage
    );

    let apiUrl = `/api/v2/orders?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (filterType && searchTerm) {
      apiUrl += `&${filterType}=${searchTerm}`;
    }
    this.auth.get(apiUrl).subscribe((res: any) => {
      if (res.status === true) {
        this.totalRecords = res.data.totalRecords;
        const orders = res.data.list || [];
        this.list = orders.map((o: { created_on: any; }) => ({
          ...o,
          created_on: this.appStateService.getFormattedDateFromISO({
            dateFormat: 'yyyy-MM-dd HH:mm a',
            isoDateStr: o.created_on,
          }),
        }));
      } else {
        this.notificationService.show({
          type: 'danger',
          message: res.result_code === 1002 ? `Search didn't return any result`: 'Could not perform this action',
        });
      }
    });
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadOrders(); // Reload the data for the selected page
  }

  updateRemarks(orderDetail: IOrderDetailEntity, remarks: string) {
    

    this.auth
      .post(
        '/api/v1/update_remarks',
        { orderId: orderDetail.id, remarks }
      )
      .subscribe((res: any) => {
        if (res.status === true) {
          this.notificationService.show({ message: 'Success' });
        }
      });
  }
  
  resetFilters(): void {
    const firstFilterType = this.filterOptions[0].value;
    this.filterForm.reset({
      filterType: firstFilterType,
    });
    this.currentPage = 1;
    this.formStateService.clearFormState(this.formInfoForPersistence.name);
    this.loadOrders();
  }
}

import { enableProdMode, importProvidersFrom } from '@angular/core';

import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { NgxPaginationModule } from 'ngx-pagination';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app.routes';
import { httpInterceptorProviders } from './app/interceptors';
import { CustomFirebaseModule } from './app/modules/custom-firebase/custom-firebase.module';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { GoogleChartsModule } from 'src/libraries-forks/angular-google-charts/google-charts.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      MatIconModule,
      RouterModule,
      AngularEditorModule,
      MatProgressSpinnerModule,
      LayoutModule,
      MatToolbarModule,
      MatButtonModule,
      MatSidenavModule,
      MatListModule,
      GoogleChartsModule,
      FlexLayoutModule,
      AppRoutingModule,
      CustomFirebaseModule,
      NgxPaginationModule
    ),
    httpInterceptorProviders,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'always' },
    },
  ],
});

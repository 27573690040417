import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGaurd } from "@guards/auth.gaurd";
import { LoginComponent } from "@pages/auth/login/login.component";
import { OrderDetailsComponent } from "@pages/orders/order-details/order-details.component";
import { OrderListComponent } from "@pages/orders/order-list/order-list.component";

import { DashboardComponent } from "@pages/dashboard/dashboard.component";

import { ProductInputPageComponent } from "@pages/product/product-input/product-input-page.component";
import { ProductListPageComponent } from "@pages/product/product-list/product-list.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },

  // static routes
  {
    path: "dashboard",
    canActivate: [AuthGaurd],
    component: DashboardComponent,
  },

  {
    path: "products",
    canActivate: [AuthGaurd],
    component: ProductListPageComponent,
  },

  {
    path: "product_input/:id",
    canActivate: [AuthGaurd],
    component: ProductInputPageComponent,
  },

  { path: "orders", canActivate: [AuthGaurd], component: OrderListComponent },

  {
    path: "order_details/:id",
    canActivate: [AuthGaurd],
    component: OrderDetailsComponent,
  },

  {
    path: "login",
    component: LoginComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

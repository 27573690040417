import { AsyncPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChartType } from "@forks/angular-google-charts/types/chart-type";
import { IDashboardApiResponse } from "@models/dashboard-api.model";
import { AppStateService } from "@services/app-state/app-state.service";
import { RequestService } from "@services/request/request.service";
import { distinctUntilChanged, map } from "rxjs";
import { GoogleChartsModule } from "src/libraries-forks/angular-google-charts/google-charts.module";

@Component({
  selector: "fh-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  standalone: true,
  imports: [GoogleChartsModule, AsyncPipe],
})
export class DashboardComponent implements OnInit {
  chartType =
    this.appStateService.deviceType$.value === "mobile"
      ? ChartType.BarChart
      : ChartType.ColumnChart;
  chartWidth$ = this.appStateService.deviceType$.pipe(
    distinctUntilChanged(), // Ensure changes in device type trigger a new value
    map((deviceType) => {
      if (deviceType === "mobile") {
        return this.appStateService.windowSize$.value?.innerWidth - 50;
      } else {
        return this.appStateService.windowSize$.value?.innerWidth;
      }
    })
  );
  dailyOrdersData: any[] = [];
  dailyOrdersOptions = this.getChartOptions({
    xAxisTitle: "Date",
    yAxisTitle: "Orders",
  });
  dailyOrderTableColumns = this.getColumns(["order_date", "#no of orders"]);
  dailySalesData: any[] = [];
  dailySalesColumn = this.getColumns(["date", "#sales"]);
  dailySalesOptions = this.getChartOptions({
    xAxisTitle: "Date",
    yAxisTitle: "Sales",
  });

  
  monthlySalesData: any[] = [];
  monthlyOrdersData: any[] = [];
  monthlySalesColumn: any[] = [
    { type: "string", label: "month" },
    { type: "number", label: "#sales" },
    { type: "number", role: "annotation" },
  ];
 
  dailyUsersCountColumn: any[] = [
    { type: "string", label: "date" },
    { type: "number", label: "#no of users" },
    { type: "number", role: "annotation" },
  ];
  

  monthlyOrdersColumn = [
    { type: "string", label: "month" },
    { type: "number", label: "#no of orders" },
    { type: "number", role: "annotation" },
  ];
  monthlyOrdersOptions = {
    hAxis: {
      title: "Month",
    },
    vAxis: {
      title: "Orders",
    },
    colors: ["#0c55b8"],
    displayAnnotations: true,
  };
  monthlySalesOptions = this.getChartOptions({
    xAxisTitle: "Month",
    yAxisTitle: "Sales",
  });

  monthlySalesAndOrders: any[] = [];
  dailySalesAndOrders: any[] = [];
  constructor(
    private request: RequestService,
    public appStateService: AppStateService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!localStorage.getItem("aqua_auth_token")) {
      this.router.navigate(["/login"]);
    }
    this.request
      .get<IDashboardApiResponse>(`/api/v1/dashboard`)
      .subscribe((res) => {
        if (res.result_code === 1) {
          this.monthlySalesAndOrders = res.data.monthly;
          this.monthlySalesData = res.data.monthly.map((item) => [
            item.month,
            item.sales,
            item.sales,
          ]);
          this.monthlyOrdersData = res.data.monthly.map((item) => [
            item.month,
            item.orders,
            item.orders,
          ]);
          this.dailySalesAndOrders = res.data.daily;

          this.dailyOrdersData = res.data.daily.map((item) => [
            item.created_on,
            item.orders,
            item.orders,
          ]);
          this.dailySalesData = res.data.daily.map((item) => [
            item.created_on,
            item.sales,
            item.sales,
          ]);
        }
      });
  }

  getChartOptions({ xAxisTitle, yAxisTitle }: any) {
    return {
      hAxis: {
        title: xAxisTitle,
      },
      vAxis: {
        title: yAxisTitle,
      },
      colors: ["#FF8040"],
      displayAnnotations: true,
    };
  }
  getColumns(arr: any[] = []) {
    return [
      { type: "string", label: arr[0] },
      { type: "number", label: arr[1] },
      { type: "number", role: "annotation" },
    ];
  }
}

<form [formGroup]="form">
  <div formArrayName="options">
    @for (option of options.controls; track option; let i = $index) {
      <div [formGroupName]="i" class="flex gap-4">
        @if (option.get('state')?.value !== 'delete') {
          <mat-form-field>
            <mat-label>Addon Name</mat-label>
            <input matInput formControlName="addon_name" />
            @if (option.get('addon_name')?.hasError('required')) {
              <mat-error>
                Addon Name is required.
              </mat-error>
            }
            @if (option.get('addon_name')?.hasError('duplicateName')) {
              <mat-error>
                Addon Name must be unique.
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>Addon Price</mat-label>
            <input matInput formControlName="price" type="number" />
            @if (option.get('price')?.hasError('required')) {
              <mat-error>
                Addon Price is required.
              </mat-error>
            }
            @if (option.get('price')?.hasError('duplicatePrice')) {
              <mat-error>
                Addon Price must be unique.
              </mat-error>
            }
          </mat-form-field>
          @if (options.length > 1) {
            <button mat-icon-button (click)="removeOption(i)">
              <mat-icon>delete</mat-icon>
            </button>
          }
        }
      </div>
    }
  </div>
  <button mat-flat-button (click)="addOption()">
    <mat-icon>add</mat-icon> Add Option
  </button>

</form>

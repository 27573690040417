<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 m-auto">
        <div class="flex flex-col">
          <div class="flex flex-row gap-4 justify-start">
            @for (category of categories; track category) {
            <button
              mat-raised-button
              [disabled]="!!this.productId"
              type="button"
              class="btn"
              (click)="categoryId.set(category.id)"
              [ngClass]="{
                'btn-primary': productCategoryId ? productCategoryId === category.id || category.id === 0 : categoryId() === category.id,
                'btn-light': productCategoryId ? productCategoryId !== category.id && [5, 1, 0].includes(productCategoryId) : categoryId() !== category.id,
                'btn-border': this.productId
              }"
            >
              {{ category.label }}
            </button>
            }
          </div>


          <div class="flex flex-col gap-4 justify-center">
            @if (productCategoryId === 1 || (!productCategoryId && categoryId() === 1)) {
            <fh-product-input
              formTitle="Product Input(Live Stock)"
              [controls]="livestockControls"
              [categoryId]="categoryId()"
              [productId]="productId!"
              (formIsValid)="productInput.valid.set($event)"
              (formChanged)="productInput.data.set($event)"
              (productCategoryId)="getProductCategoryId($event)"
            >
            </fh-product-input>
            } @else if (productCategoryId === 5 || (!productCategoryId && categoryId() === 5)) {
            <fh-product-input
              formTitle="Product Input(Tank)"
              [controls]="tankInputControls"
              [categoryId]="categoryId()"
              [productId]="productId!"
              (formIsValid)="productInput.valid.set($event)"
              (formChanged)="productInput.data.set($event)"
              (productCategoryId)="getProductCategoryId($event)"
            >
            </fh-product-input>
            } @else {
            <fh-product-input
              formTitle="Product Input(Live Stock)"
              [controls]="otherProductInputControls"
              [categoryId]="categoryId()"
              [productId]="productId!"
              (formIsValid)="productInput.valid.set($event)"
              (formChanged)="productInput.data.set($event)"
              (productCategoryId)="getProductCategoryId($event)"
            >
            </fh-product-input>
            }
          </div>

          <div class="flex flex-col gap-4 justify-center">
            <div>
              <span class="text-danger">{{ error }}</span>
              <span class="text-success">{{ success }}</span>
            </div>

            <button
              mat-raised-button
              type="button"
              (click)="saveProduct()"
              [disabled]="!productInput.valid()"
              class="btn btn-danger pull-right"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { environment } from '@environments/environment';
import { ICategoryList, ICategoryListEntity, IProductListApiResponse, IProductResponseDto } from '@models/product-list.model';
import { ExtendedModule, FlexModule } from '@ngbracket/ngx-layout';
import { RequestService } from '@services/index';
import { ProductService } from '@services/product/product.service';

@Component({
  selector: 'fh-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatTableModule,
    MatSort,
    NgClass,
    DecimalPipe,
    DatePipe,
    RouterLink,
    MatPaginatorModule,
    FlexModule,
    ExtendedModule
],
})
export class ProductListPageComponent implements OnInit, AfterViewInit {
  access = localStorage.getItem('aqua_access');
  user_id = localStorage.getItem('aqua_userid');
  productListByCategoryId: ICategoryListEntity[] = [];

  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;
  searchText = '';
  highlightedRow!: number; // It will hold id of selected row
  productList: IProductResponseDto[] = [];
  dataSource = new MatTableDataSource<IProductResponseDto>(
    this.productList
  );
  pageIndex: string | number = 0;
  pageSize: string | number = 15;
  orderField = '';
  order = '';
  category_id!: string | number;
  total!: number;
  totalProducts = 0;
  merchantId = 0;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  totalProductsAllCategories!: number | string;
  @ViewChild('input', { static: false })
  searchWords!: ElementRef;
  displayedColumns: string[] = [
    'id',
    'image',
    'name',
    'displayPrice',
    'quantity',
    'deliveryCharges',
    'subcategory',
    'productExpiry',
    'action',
    'status',
  ];

  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  merchantList: { quantity: number, vendor_id: number, name: string}[] = [];
  
  constructor(
    private router: Router,
    private auth: RequestService,
    private cdr: ChangeDetectorRef,
    private productService: ProductService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      success_msg: string;
      error_msg: string;
    };

    if (!state) {
      return;
    }
    if (state.success_msg) {
      this.success_msg = state.success_msg;
      setTimeout(() => {
        this.success_msg = '';
      }, 3000);
    } else if (state.error_msg) {
      this.error_msg = state.error_msg;
      setTimeout(() => {
        this.error_msg = '';
      }, 3000);
    }
  }

  ngOnInit(): void {
    this.fetchProducts();
    this.fetchItemCountForCategories();
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  fetchItemCountForCategories() {
    this.auth
      .get<ICategoryList>(
      `/api/v1/vendor/vendor_category_list?id=${this.user_id}`
      )
      .subscribe((res: ICategoryList) => {
        this.totalProducts = res.data.count;
        this.totalProductsAllCategories = res.data.count;
        this.productListByCategoryId = [];
        res.data.list?.forEach((value) => {
          this.productListByCategoryId.push({
            id: value.id,
            category_name: value.category_name,
            quantity: value.quantity,
          });
        });
      });
  }

  fetchProducts(options?: { expired: boolean }) {
    const { expired } = options || {};
    const data = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      orderField: expired ? 'expires_at' : this.orderField,
      order: expired ? 'asc' : this.order,
      search: this.searchText,
      category: this.category_id,
      expired,
    };
    const api = `/api/v1/product_list`;
    this.auth
      .post<IProductListApiResponse>(api, data)
      .subscribe((res: IProductListApiResponse) => {
        if (res.result_code !== 1) {
          return;
        }

        if (!res.data.list?.length) {
          this.productList = [];
          this.total = res.data.count;
          this.dataSource = new MatTableDataSource<IProductResponseDto>(
            this.productList
          );
          this.cdr.detectChanges();
          return;
        }
        this.productList = res.data.list
          .filter((i: any) => !!i)
          .map((d: IProductResponseDto) => ({ ...d, status: d.status?.trim() }));
        this.success_msg = '';
        this.total = res.data.count;
        this.dataSource = new MatTableDataSource<IProductResponseDto>(
          this.productList
        );
        console.log("productList", this.productList);
        console.log("dataS", this.dataSource);
        this.cdr.detectChanges();
      });
  }
  onCategorySelection(id: any) {
    if (!id) {
      return;
    }
    this.category_id = id ? id : '';
    this.pageIndex = 0;
    this.searchWords.nativeElement.value = '';
    this.searchText = '';
    if (id === 'expired') {
      this.fetchProducts({ expired: true });
    } else {
      this.fetchProducts();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchText = filterValue.trim().toLowerCase();
    this.pageIndex = 0;
    this.fetchProducts();
  }

  // this func highlights a row
  highlight(id: number) {
    this.highlightedRow = id;
  }
  // this function deletes a row
  delete(id: number) {
    const product_id = id;
    const user_id = localStorage.getItem('aqua_userid');
    if (confirm('Are you sure ?')) {
      this.auth
        .delete(
          `/api/v1/vendor/vendor_product_list?vendor_id=${user_id}&prod_id=${product_id}`
        
        )
        .subscribe((res: any) => {
          if (res.result_code) {
            this.success_msg = 'Deleted successfully';
            this.productList.splice(
              this.productList.findIndex((val: { id: number; }) => val.id === product_id),
              1
            );
            this.dataSource = new MatTableDataSource<IProductResponseDto>(
              this.productList
            );
            this.success_msg = 'Deleted Successfully';
            this.cdr.detectChanges();
            setTimeout(() => {
              this.success_msg = '';
            }, 7000);
          }
        });
    }
  }
  resetProductExpiry(product: IProductResponseDto) {
    if (!confirm('This action will restart the expiry timer')) {
      return;
    }

    const newExpiry = this.productService.resetProductExpiry({
      expiresAt: product.expires_at!,
      expiryUpdatedAt: product.expiry_updated_at!,
    });

    this.auth
      .put(
        `/api/v1/product-expiry/reset?productId=${
          product.id
        }&newExpiry=${newExpiry.toISOString()}`
      )
      .subscribe((res: any) => {
        if (res.result_code) {
          this.success_msg = res.message;
          product.expires_at = newExpiry.toISOString();
          product.status = 'active';
        } else {
          this.error_msg = res.message;
        }

        setTimeout(() => {
          this.success_msg = '';
          this.error_msg = '';
        }, 4000);
      });
  }

  sortData(sort: Sort) {
    // API call goes here
    this.orderField = sort.active;
    this.order = sort.direction;
    this.success_msg = 'Loading...';
    this.fetchProducts();
  }
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchProducts();
  }
  onMerchantSelection(id: number) {
    if (typeof id === 'string') {
      id = parseInt(id, 10);
    }
    this.merchantId = id;
    this.pageIndex = 0;
    this.category_id = 'all';
    this.fetchItemCountForCategories();
    this.fetchProducts();
    this.cdr.detectChanges();
  }

  isProductExpired(expiryDate: string | number | Date) {
    return new Date(expiryDate) < new Date();
  }
}

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false && !!token"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <div class="p-2 text-xs">
      {{ (currentUser | async)?.name }}
      <br />
      {{ (currentUser | async)?.email }}
    </div>
    @if (!!token) {
    <mat-nav-list>
      @for (menuItem of menuItems; track menuItem) {
      <div mat-list-item>
        <a
          class="nav-link"
          routerLinkActive="active"
          [routerLink]="[menuItem.path]"
        >
          <i class="material-icons mr-2">{{ menuItem.icon }}</i
          >{{ menuItem.title }}</a
        >
      </div>
      }
      <div mat-list-item>
        <div (click)="logout()" (keydown.enter)="logout()" tabindex="0  ">
          <i class="material-icons mr-2">settings_power</i>Logout
        </div>
      </div>
    </mat-nav-list>
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      @if (isHandset$ | async) {
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      }
      <span>Fishyhub {{ access | titlecase }} Portal</span>
    </mat-toolbar>
    <ng-content></ng-content>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>

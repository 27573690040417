<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Orders Basic Details</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-primary">Order-ID</th>
                    <th># {{ orderId }}</th>
                    <th class="text-primary">Customer Name</th>
                    <th class="text-capitalize">{{ orderDetails.customer_name }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Shipping Address</th>
                    <th>{{ orderDetails.shipping_address }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Sub Total</th>
                    <th>{{ orderDetails.currency }} {{ orderDetails.sub_total | number : '1.2-2' }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Order On</th>
                    <th>{{ orderDetails.created_on | date : 'medium' }}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Orders Details (Product List)</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Option</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of list; track row) {
                  <tr>
                    <td>{{ row.product_id }}</td>
                    <td class="text-capitalize">{{ row.product_name }}</td>
                    <td>
                      @if (row.product_image) {
                      <img
                        src="{{ row.product_image }}"
                        alt="Product Image"
                        width="80"
                        height="80"
                      />
                      }
                    </td>
                    <td>
                      {{ row.addon_name }} / {{ orderDetails.currency }}
                      {{ row.price | number : '1.2-2' }}
                    </td>
                    <td>{{ row.quantity }}</td>
                    <td>{{row.productTotalPrice}}</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

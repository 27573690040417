<div fxLayout="row">
  <fh-main-nav fxFlex="100">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="end center">
        @if (apiMessageService.message) {
          <div
            fxFlex="300px"
            fxFlex.xs="100"
            class="alert api-alert-container"
          [ngClass]="{
            'alert-success': apiMessageService.messageType === 'success',
            'alert-danger': apiMessageService.messageType === 'error'
          }"
            role="alert"
            >
            <div>{{ apiMessageService.message }}</div>
            @if (apiMessageService.actionButtonConfig) {
              <button
                mat-button
                class="btn btn-lg"
                [routerLink]="[apiMessageService.actionButtonConfig.navigateTo]"
                >
                {{ apiMessageService.actionButtonConfig.text }}
              </button>
            }
          </div>
        }
      </div>

      <router-outlet></router-outlet>
    </div>
  </fh-main-nav>
</div>
<fh-loader></fh-loader>
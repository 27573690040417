import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormStateService {
  private formState: Record<string, any> = {};

  constructor() {
    this.loadFormState();
  }

  setFormValue(formName: string, controlName: string, value: any): void {
    if (!this.formState[formName]) {
      this.formState[formName] = {};
    }
    this.formState[formName][controlName] = value;
    this.saveFormState();
  }

  getFormValue(formName: string, controlName: string): any {
    return this.formState[formName]?.[controlName];
  }

  clearFormState(formName: string): void {
    delete this.formState[formName];
    this.saveFormState();
  }

  private saveFormState(): void {
    localStorage.setItem("formState", JSON.stringify(this.formState));
  }

  private loadFormState(): void {
    const savedFormState = localStorage.getItem("formState");
    if (savedFormState) {
      this.formState = JSON.parse(savedFormState);
    }
  }
}

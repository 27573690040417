export const environment = {
  env: "prod",
  production: true,
  backendURL: "https://fishyhub-merchant-portal-backend-prod.fishyhub.com",
  standard_width: 768,
  firebaseConfig: {
    apiKey: "AIzaSyD5n0oUsWsWDGWC-Is6Uejd9BrKvjQOeLI",
    authDomain: "fishyhub-prod.firebaseapp.com",
    databaseURL: "https://fishyhub-prod-default-rtdb.firebaseio.com",
    projectId: "fishyhub-prod",
    storageBucket: "fishyhub-prod.appspot.com",
    messagingSenderId: "46968976444",
    appId: "1:46968976444:web:4723b346063fed5aa47f39",
    measurementId: "G-RPR848JHYZ",
  },
};

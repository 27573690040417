import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { MatButton } from '@angular/material/button';
import { RouterLink, RouterOutlet } from '@angular/router';
import { LoaderComponent } from '@components/loader/loader.component';
import { MainNavComponent } from '@components/main-nav/main-nav.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  debounceTime,
  fromEvent,
  startWith,
  Subject,
  Subscription,
  takeUntil
} from 'rxjs';
import { ApiMessageService, AppStateService } from './services';


@Component({
  selector: 'fh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    MainNavComponent,
    FlexLayoutModule,
    NgClass,
    MatButton,
    RouterLink,
    RouterOutlet, 
    LoaderComponent
],
})
export class AppComponent implements OnInit {
  resizeSubscription: Subscription | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public apiMessageService: ApiMessageService,
    private appStateService: AppStateService
  ) {}
  ngOnInit() {
    const token = localStorage.getItem('aqua_auth_token') ?? '';
    this.appStateService.userToken$.next(token);

    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(startWith(null), debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => {
        this.appStateService.windowSize$.next({
          innerHeight: window.innerHeight,
          innerWidth: window.innerWidth,
        });

        console.log(this.appStateService.windowSize$.value);

        this.appStateService.deviceType$.next(
          window.innerWidth < 768 ? 'mobile' : 'desktop'
        );
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}

import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  ViewChild,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { IProductMediaListRequestDto, IProductMediaRequestDto } from '@models/product-list.model';


export interface IMediaPicker {
  images: IProductMediaListRequestDto;
  videos: IProductMediaListRequestDto;
}

@Component({
  selector: 'fh-media-picker',
  templateUrl: './media-picker.component.html',
  styleUrls: ['./media-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaPickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [MatError, MatIcon, MatButton, NgClass],
})
export class MediaPickerComponent implements ControlValueAccessor {
  @ViewChild('multifile') multifile!: ElementRef<HTMLInputElement>;
  @ViewChild('multivdo') multivdo!: ElementRef<HTMLInputElement>;

  images = signal<IProductMediaListRequestDto>([]);
  videos = signal<IProductMediaListRequestDto>([]);

  deletedImages = signal<IProductMediaListRequestDto>([]);
  deletedVideos = signal<IProductMediaListRequestDto>([]);

  imageErrorMsg = '';
  videoErrorMsg = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  writeValue(obj: IMediaPicker | null): void {
    if (obj) {
      this.images.set(obj.images || []);
      this.videos.set(obj.videos || []);
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onImageSelection(event: Event): void {
    this.handleFileSelection(event, 'image', (result) => {
      this.images.update((prev) => [...prev, { base64: result, state: 'add' }]);
      console.log(this.images());
      this.updateFormValue();
    });
  }

  onVideoSelection(event: Event): void {
    this.handleFileSelection(event, 'video', (result, ext, file) => {
      this.videos.update((prev) => [
        ...prev,
        { base64: result, state: 'add', file },
      ]);
      this.updateFormValue();
    });
  }

  handleFileSelection(
    event: Event,
    type: 'image' | 'video',
    callback: (result: string, ext: string, file?: File) => void
  ): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const file = input.files[0];
      const reader = new FileReader();
      const ext = file.name.split('.').pop()?.toLowerCase() || '';

      reader.onload = () => {
        callback(reader.result as string, ext, file);
      };
      reader.readAsDataURL(file);

      input.value = '';
    }
  }

  deleteMedia(index: number, mediaType: 'image' | 'video'): void {
    const mediaList = mediaType === 'image' ? this.images() : this.videos();
    const updateMedia =
      mediaType === 'image'
        ? (items: IProductMediaListRequestDto) => this.images.set(items)
        : (items: IProductMediaListRequestDto) => this.videos.set(items);

    if (mediaList[index].state === 'add') {
      // If the item is newly added, remove it from the list
      const updatedMediaList = mediaList.filter((_, i) => i !== index);
      updateMedia(updatedMediaList as IProductMediaListRequestDto);
    } else {
      // Otherwise, mark the item as deleted
      const updatedMediaList = mediaList.map((item, i) =>
        i === index ? ({ ...item, state: 'delete' } as IProductMediaRequestDto) : item
      );

      const { mediaToShow, mediaToDelete } = updatedMediaList.reduce(
        (
          acc: {
            mediaToShow: IProductMediaListRequestDto;
            mediaToDelete: IProductMediaListRequestDto;
          },
          item
        ) => {
          if (item.state === 'delete') {
            acc.mediaToDelete.push(item);
          } else {
            acc.mediaToShow.push(item);
          }
          return acc;
        },
        { mediaToShow: [], mediaToDelete: [] }
      );

      updateMedia(updatedMediaList as IProductMediaListRequestDto);
      // Also update the original list to include this deleted item
      if (mediaType === 'image') {
        this.images.set(mediaToShow);
        this.deletedImages.update((prev) => [...prev, ...mediaToDelete]);
      } else {
        this.videos.set(mediaToShow);
        this.deletedVideos.update((prev) => [...prev, ...mediaToDelete]);
      }
    }

    this.updateFormValue();
  }

  // Wrapper methods for images and videos
  deleteImage(index: number): void {
    this.deleteMedia(index, 'image');
  }

  deleteVideo(index: number): void {
    this.deleteMedia(index, 'video');
  }

  markAsFeatured(index: number): void {
    // Unmark any currently featured image
    this.images.update((prev) =>
      prev.map((item, i) => ({
        ...item,
        is_featured: i === index,
      }))
    );
    this.updateFormValue();
  }

  removeFeatured(index: number): void {
    // Unmark the featured image
    this.images.update((prev) =>
      prev.map((item, i) => ({
        ...item,
        is_featured: i === index ? false : item.is_featured,
      }))
    );
    this.updateFormValue();
  }

  emptyBoxes(array: IProductMediaListRequestDto, fileType: 'video' | 'image'): number[] {
    const maxSize = fileType === 'video' ? 8 : 36;
    const activeItems = array.filter((item) => item.state !== 'delete').length;
    
    // Return array with single element if we haven't reached minSize
    if (activeItems < maxSize) {
      return [0];
    }
    
    // Return empty array if we've reached or exceeded minSize
    return [];
  }

  updateFormValue(): void {
    this.onChange({
      images: [...this.images(), ...this.deletedImages()],
      videos: [...this.videos(), ...this.deletedVideos()],
    });
  }
}

<section class="product-images-videos">
  <fieldset>
    <legend>Images</legend>
    <div class="col-md-12">
      <div class="row d-flex">
        <!-- Images -->
        @for (img of images(); track img; let i = $index) {
        <div class="col-md-3 upload-img">
          <img class="img" [src]="img.product_img || img.base64" alt="" />
          <mat-icon
            class="delete-button cursor-pointer"
            (click)="deleteImage(i)"
          >
            delete
          </mat-icon>
          <span class="image-info flex flex-col gap-1">
            <span
              class="badge cursor-pointer"
              (click)="img.is_featured ? removeFeatured(i) : markAsFeatured(i)"
              [ngClass]="
                img.is_featured ? 'featured-media' : 'unfeatured-media'
              "
              (keydown.enter)="
                img.is_featured ? removeFeatured(i) : markAsFeatured(i)
              "
              (keydown.space)="
                img.is_featured ? removeFeatured(i) : markAsFeatured(i)
              "
              tabindex="0"
              >Featured</span
            >
            @if (img.state === 'add') {
            <span class="badge new-media">New</span>
            }
          </span>
        </div>
        }
        <!-- Empty Image Boxes -->
        @for (i of emptyBoxes(images(), 'image'); track i) {
        <div class="col-md-3 upload-img">
          <div
            class="empty-box"
            (click)="multifile.click()"
            (keydown.enter)="multifile.click()"
            (keydown.space)="multifile.click()"
            tabindex="0"
            role="button"
          >
            <span>+</span>
            <input
              #multifile
              type="file"
              (change)="onImageSelection($event)"
              style="display: none"
              accept="image/*"
            />
          </div>
        </div>
        }
      </div>
      @if (imageErrorMsg) {
      <mat-error>{{ imageErrorMsg }}</mat-error>
      }
    </div>
  </fieldset>

  <fieldset>
    <legend>Videos</legend>
    <div class="col-md-12">
      <div class="row d-flex">
        <!-- Videos -->
        @for (vid of videos(); track vid; let i = $index) {
        <div class="col-md-3 upload-img">
          <video width="320" height="240" controls>
            <source [src]="vid.product_img || vid.base64" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <mat-icon
            class="delete-button cursor-pointer"
            (click)="deleteVideo(i)"
          >
            delete
          </mat-icon>
          @if (vid.state === 'add') {
          <span class="badge badge-info">New</span>
          }
        </div>
        }
        <!-- Empty Video Boxes -->
        @for (i of emptyBoxes(videos(), 'video'); track i) {
        <div class="col-md-3 upload-img">
          <div
            class="empty-box"
            (click)="multivdo.click()"
            (keydown.enter)="multivdo.click()"
            (keydown.space)="multivdo.click()"
            tabindex="0"
            role="button"
          >
            <span>+</span>
            <input
              #multivdo
              type="file"
              (change)="onVideoSelection($event)"
              style="display: none"
              accept="video/*"
            />
          </div>
        </div>
        }
      </div>
      @if (videoErrorMsg) {
      <mat-error>{{ videoErrorMsg }}</mat-error>
      }
    </div>
  </fieldset>
</section>

<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Daily Orders"
        [type]="chartType"
        [options]="dailyOrdersOptions"
        [data]="dailyOrdersData"
        [columns]="dailyOrderTableColumns"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Daily Sales"
        [type]="chartType"
        [options]="dailySalesOptions"
        [data]="dailySalesData"
        [columns]="dailySalesColumn"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
    </div>

    <div class="row">
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Monthly Orders"
        [type]="chartType"
        [data]="monthlyOrdersData"
        [columns]="monthlyOrdersColumn"
        [options]="monthlyOrdersOptions"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
      <fh-gc-google-chart
        class="col-12 col-sm-12 col-md-12"
        #chart
        title="Monthly Sales"
        [type]="chartType"
        [data]="monthlySalesData"
        [columns]="monthlySalesColumn"
        [options]="monthlySalesOptions"
        [width]="(chartWidth$ | async)!"
        [height]="700"
      >
      </fh-gc-google-chart>
    </div>

    Ø
  </div>
</div>

import { Component, OnInit } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AppStateService, RequestService } from '@services/index';

@Component({
  selector: 'fh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [MatButtonModule, FormsModule, MatInputModule],
})
export class LoginComponent implements OnInit {
  username = '';
  usenameErr = '';
  password = '';
  passwordErr = '';
  authEror = '';
  decoded: any;
  token = '';

  constructor(
    private router: Router,
    private auth: RequestService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('aqua_auth_token')) {
      this.router.navigate(['/']);
    }
  }

  keyDownFunction(event: { keyCode: number; }) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  login() {
    this.usenameErr = '';
    this.passwordErr = '';
    if (!this.username) {
      this.usenameErr = 'Username is required.';
    }
    if (!this.password) {
      this.passwordErr = 'Password is required.';
    }
    const data = {
      email: this.username,
      password: this.password,
    };
    this.auth.post('/api/v1/login', data).subscribe((res: any) => {
      if (res.status === true) {
        localStorage.setItem('access-token', res.data.token);
        this.appStateService.userToken$.next(res.data.token);
        localStorage.setItem('aqua_userid', res.data.user_id);
        const name = (res.data.firstname?? '') + ' ' + (res.data.lastname?? '');
        const email = res.data.email;
        this.appStateService.user$.next({ name, email });
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);
        localStorage.setItem('aqua_access', res.data.access);
        localStorage.setItem('aqua_auth_token', res.data.token);
        this.appStateService.user$.next(res.data);
        this.router.navigate(['/dashboard']);
      } else {
        this.authEror = res.message;
      }
    });
  }
}

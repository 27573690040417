<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        @if (success_msg !== '') {
          <div class="alert alert-green">
            <span>{{ success_msg }}</span>
          </div>
        }
        <div class="card">
          <div class="card-header bg-danger text-white">
            Orders List
          </div>

          <div class="card-body">
            <div>
              <form
                [formGroup]="filterForm"
                (ngSubmit)="loadOrders()"
                class="form-inline"
                >
                <div class="form-group">
                  <label for="filterType" class="mr-2">Search for:</label>
                  <select id="filterType" class="form-control" formControlName="filterType">
                    @for (option of filterOptions; track option) {
                      <option [value]="option.value">{{ option.label }}</option>
                    }
                  </select>
                </div>
                <div class="form-group mx-sm-3">
                  @if (filterForm.controls['filterType'].value !== 'orderStatus') {
                    <input
                      type="text"
                      class="form-control"
                      formControlName="searchTerm"
                      />
                  }
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary mr-2">
                    Search
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="resetFilters()"
                    >
                    Reset
                  </button>
                </div>
              </form>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Customer Name</th>
                    <th>Currency</th>
                    <th>Shipping Total</th>
                    <th>Sub Total</th>
                    <th>Total</th>
                    <th>Order Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    row of list
                    | paginate
                    : {
                    itemsPerPage: itemsPerPage,
                    currentPage: currentPage,
                    totalItems: totalRecords
                  }
                  ; track
                  row) {
                  <tr
                    >
                    <td>
                      <a
                        class="d-flex"
                        [routerLink]="['/order_details/' + row.id]"
                        >
                        <span
                          _ngcontent-gxv-c19=""
                          class="material-icons icon-image-preview"
                          >visibility</span
                          >
                          {{ row.id }}
                        </a>
                      </td>
                      <td class="text-capitalize">{{ row.customer_name }}</td>
                      <td>{{ row.currency }}</td>
                      <td>{{ row.shipping_total | number : '1.2-2' }}</td>
                      <td>{{ row.sub_total | number : '1.2-2' }}</td>
                      <td>{{ row.total | number : '1.2-2' }}</td>
                      <td>{{ row.created_on }}</td>
                      <td class="d-flex">
                        <input #remarks [value]="row.remarks" />
                        <button (click)="updateRemarks(row, remarks.value)">
                          <i class="material-icons">done</i>
                        </button>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              <!-- Add the pagination component at the bottom of the table -->
              <pagination-controls
                (pageChange)="onPageChange($event)"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="mr-auto">Success</strong>
  </div>
</div>

import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { IUser } from '@models/user.model';
import { format, parseISO } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { useSubject } from '../../utils';
@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  windowSize$ = new BehaviorSubject<{
    innerWidth: number;
    innerHeight: number;
  } >(null as any);
  deviceType$ = new BehaviorSubject<'desktop' | 'mobile' >(null as any);
  routeNavigationEnd$ = useSubject<NavigationEnd>(null as any);
  userToken$ = new BehaviorSubject<string>('');
  user$ = new BehaviorSubject<IUser>({
    name: localStorage.getItem('name') || '',
    email: localStorage.getItem('email') || '',
  });
  getFormattedDateFromISO({
    isoDateStr,
    dateFormat,
  }: {
    isoDateStr: string;
    dateFormat: 'yyyy-MM-dd HH:mm a';
  }): string {
    return format(parseISO(isoDateStr), dateFormat);
  }



  apiLoading$ = new BehaviorSubject<boolean>(false);


  constructor() {

    this.apiLoading$.subscribe((loading) => {
      console.log('apiLoading$', loading);
    });
  }
}

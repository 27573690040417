import { ProductControls } from '@components/product-input/product-input.component';

const commonControls: ProductControls[] = [
  ProductControls.ProductName,
  ProductControls.DisplayPrice,
  ProductControls.Qty,
  ProductControls.CategoryId,
  ProductControls.SubcategoryId,
  ProductControls.Status,
  ProductControls.MoreDescription,
  ProductControls.DestinationCountryId,

  ProductControls.ProductMedia,
  ProductControls.ProductOptions,
];
export const livestockInputControls: ProductControls[] = [
  ...commonControls,
  ProductControls.SubsubcategoryId,
  ProductControls.IsSeasonal,
  ProductControls.ProductExpiry,
];

export const tankInputControls: ProductControls[] = [
  ...commonControls,
  ProductControls.CapacityInGallons,
];

export const otherProductInputControls: ProductControls[] = [
  ...commonControls,
];

// loader.interceptor.ts
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppStateService } from '@services/index';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private appStateService: AppStateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiUrl = req.url.startsWith(environment.backendURL);
    if (isApiUrl) {
     this.appStateService.apiLoading$.next(true);
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (isApiUrl) {
          this.appStateService.apiLoading$.next(false);
        }
      })
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private http: HttpClient) {}

  get<T>(baseurl: string, jwtToken?: undefined) {
    return this.http.get<T>(environment.backendURL + baseurl);
  }
  post<T>(baseurl: string, data?: any, jwtToken?: string | null | undefined) {
    return this.http.post<T>(environment.backendURL + baseurl, data);
  }
  put<T>(baseurl: string, data?: any, jwtToken?: any) {
    return this.http.put<T>(environment.backendURL + baseurl, data);
  }
  delete<T>(baseurl: string, jwtToken?: any) {
    return this.http.delete<T>(environment.backendURL + baseurl);
  }
}

<div class="main-content">
  <div class="container-fluid">
    <div class="row" id="loginContainer">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Login</h4>
            <p class="card-category">login to your account</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 space">
                <input [(ngModel)]="username" matInput placeholder="Username" (keydown)="keyDownFunction($event)">
                  <!-- <mat-form-field class="example-full-width">
                </mat-form-field> -->
                <span class="text-danger">{{ usenameErr }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 space">
                <input [(ngModel)]="password" matInput placeholder="Password" type="password" (keydown)="keyDownFunction($event)">
                  
                <span class="text-danger">{{ passwordErr }}</span>
              </div>
            </div>
            <span class="text-danger">{{ authEror }}</span>
            <button mat-raised-button type="button" (click)="login()" class="btn btn-danger pull-right">Login</button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { sizePattern } from '@app/regex';

@Component({
  selector: 'fh-product-options',
  templateUrl: './product-options.component.html',
  styleUrls: ['./product-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductOptionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProductOptionsComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule]
})
export class ProductOptionsComponent implements OnInit, ControlValueAccessor {
  form!: FormGroup;

  @Input() price = 0;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      options: this.fb.array([this.createOption(this.price)])
    });

    this.form.valueChanges.subscribe(value => {
      this.onChange(value.options);
      this.onTouched();
    });

    this.form.statusChanges.subscribe(() => {
      this.onValidatorChange();
    });
  }

  get options(): FormArray {
    return this.form.get('options') as FormArray;
  }

  createOption(price: number, state = 'edit'): FormGroup {
    return this.fb.group({
      addon_name: ['', [Validators.required, this.duplicateNameValidator()]],
      price: [price, [Validators.required, Validators.min(1), Validators.pattern(sizePattern)]],
      state: [state]
    });
  }

  addOption(): void {
    this.options.push(this.createOption(0, 'add'));
  }

  removeOption(index: number): void {
    const control = this.options.at(index);
    if (control.get('state')?.value === 'add') {
      this.options.removeAt(index);
    } else {
      control.patchValue({ state: 'delete' });
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setControl('options', this.fb.array(value.map((v: any) => this.fb.group({...v, state: 'edit'}))));
    } else {
      this.form.reset();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onValidatorChange: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: any): void {
    this.onValidatorChange = fn;
  }

  validate(): ValidationErrors | null {
    return this.form.invalid ? { invalid: true } : null;
  }

  private duplicateNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = control.value;
      const options = (control.parent?.parent as FormArray)?.controls || [];

      const hasDuplicate = options.some((group: AbstractControl) =>
        group !== control.parent && group.get('addon_name')?.value === name && group.get('state')?.value !== 'delete'
      );

      return hasDuplicate ? { duplicateName: true } : null;
    };
  }

}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { decimalNumber, phoneNumberPattern } from '@app/regex';
import { ChipsMultiSelectComponent } from '@components/chips-multi-select/chips-multi-select.component';
import {
  IMediaPicker,
  MediaPickerComponent,
} from '@components/media-picker/media-picker.component';
import { ProductOptionsComponent } from '@components/product-options/product-options.component';

import { ngxEditorConfig } from '@config/angular-editor.config';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IApiResponse } from '@models/api-response.model';
import {
  IProductRequestDto,
  IProductResponseDto,
} from '@models/product-list.model';
import { RequestService } from '@services/index';
import { ProductService } from '@services/product/product.service';
export enum ProductControls {
  ProductName = 'product_name',
  Qty = 'qty',
  DestinationCountryId = 'country_id',
  DisplayPrice = 'display_price',
  VendorId = 'vendor_id',
  CategoryId = 'category_id',
  Status = 'status',
  MoreDescription = 'more_description',
  ProductImageUrl = 'product_image',
  IsFeatured = 'is_featured',
  DeliveryExpress = 'delivery_express_charges',
  SubcategoryId = 'subcategory_id',
  SubsubcategoryId = 'subsubcategory_id',
  IsSeasonal = 'is_seasonal',
  ProductExpiry = 'expires_at',
  ProductMedia = 'productMedia',
  ProductOptions = 'productOptions',
  CapacityInGallons = 'capacity_in_gallons',
}

export enum ProductExpiry {
  NoExpiry = 'No Expiry',
  Expired = 'Expired',
  ExtendExpiry = 'Extend Expiry By 7 Days',
}

export interface IProductExpiryOption {
  value: string | null;
  label: ProductExpiry;
}

export enum ProductListingStatus {
  Active = 'active',
  Inactive = 'inactive',
  AuctionActive = 'auction_active',
}

@Component({
  selector: 'fh-product-input',
  templateUrl: './product-input.component.html',
  styleUrls: ['./product-input.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    ChipsMultiSelectComponent,
    ProductOptionsComponent,
    AngularEditorModule,
    MediaPickerComponent,
    MatChipsModule,
    MatIconModule,
  ],
})
export class ProductInputComponent implements OnInit {
  // Lets accept input from user about list of controls to be added in the form
  // This will be used to render the form controls
  @Input() controls: ProductControls[] = [];
  @Input() formTitle!: string;
  @Input() categoryId!: number;
  @Input() productId = 0;
  @Input() userId!: string;
  @Output() formChanged = new EventEmitter<IProductRequestDto>();
  @Output() formIsValid = new EventEmitter<boolean>();
  @Output() productCategoryId = new EventEmitter<number>();

  editorConfig = ngxEditorConfig;

  countries: any[] = [];
  vendors: any[] = [];

  food_products: any[] = [];

  categories: any[] = [];

  subcategories: any[] = [];

  subsubcategories: any[] = [];

  productForm!: FormGroup;
  productExpiryHintText!: string;
  private formControls: { [key in ProductControls]?: FormControl<any> } = {};
  productExpiryOptions: IProductExpiryOption[] = [];
  productListingStatusOptions = [
    {
      key: ProductListingStatus.Active,
      value: 'Active',
    },
    {
      key: ProductListingStatus.Inactive,
      value: 'Inactive',
    },
    {
      key: ProductListingStatus.AuctionActive,
      value: 'Auction Active',
    },
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private auth: RequestService,
    private cdr: ChangeDetectorRef,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getCountryList();

    if (!this.productId) {
      this.buildForm({} as IProductResponseDto);
      this.getCategories();
    } else {
      this.auth
        .get<IApiResponse<IProductResponseDto>>(
          `/api/v2/product?productId=${this.productId}`
        )
        .subscribe((res) => {
          if (res.status === true) {
            this.buildForm(res.data);
            this.categoryId = res.data.category_id;
            this.productCategoryId.emit(this.categoryId);
            this.getCategories();
            this.getSubCategories(res.data.category_id);

            if (res.data.subcategory_id) {
              this.getSubSubCategories(res.data.subcategory_id);
            }
          } else {
            this.router.navigate(['/products']);
          }
        });
    }
  }

  buildForm(data: IProductResponseDto) {
    // Initialize form controls based on provided controls
    if (this.controls.includes(ProductControls.ProductName)) {
      this.formControls[ProductControls.ProductName] = new FormControl(
        data.product_name || '',
        Validators.required
      );
    }
    if (this.controls.includes(ProductControls.Qty)) {
      this.formControls[ProductControls.Qty] = new FormControl(data.qty || 0, [
        Validators.required,
        Validators.pattern(phoneNumberPattern),
      ]);
    }

    if (this.controls.includes(ProductControls.DestinationCountryId)) {
      this.formControls[ProductControls.DestinationCountryId] = new FormControl(
        data.country_id || 0,
        [Validators.required, Validators.pattern(phoneNumberPattern)]
      );
    }

    if (this.controls.includes(ProductControls.CategoryId)) {
      this.formControls[ProductControls.CategoryId] = new FormControl(
        data.category_id || 0,
        [Validators.required, Validators.pattern(phoneNumberPattern)]
      );
    }
    if (this.controls.includes(ProductControls.Status)) {
      this.formControls[ProductControls.Status] = new FormControl(
        data.status || ProductListingStatus.Active
      );
    }
    if (this.controls.includes(ProductControls.MoreDescription)) {
      this.formControls[ProductControls.MoreDescription] = new FormControl(
        data.more_description || ''
      );
    }

    if (this.controls.includes(ProductControls.DisplayPrice)) {
      this.formControls[ProductControls.DisplayPrice] = new FormControl(
        data.display_price || 0,
        [
          Validators.pattern(decimalNumber),
        ]
      );
    }

    if (this.controls.includes(ProductControls.SubcategoryId)) {
      this.formControls[ProductControls.SubcategoryId] = new FormControl(
        data.subcategory_id || 0,
        [Validators.required, Validators.pattern(phoneNumberPattern)]
      );
    }

    if (this.controls.includes(ProductControls.SubsubcategoryId)) {
      this.formControls[ProductControls.SubsubcategoryId] = new FormControl(
        data.subsubcategory_id || 0,
        [Validators.required, Validators.pattern(phoneNumberPattern)]
      );
    }

    if (this.controls.includes(ProductControls.IsSeasonal)) {
      this.formControls[ProductControls.IsSeasonal] = new FormControl(
        data.is_seasonal || false
      );
    }

    if (this.controls.includes(ProductControls.ProductExpiry)) {
      this.formControls[ProductControls.ProductExpiry] = new FormControl(
        data.expires_at || null
      );
      this.setupProductExpiry({ currentExpiry: data.expires_at });
    }

    if (this.controls.includes(ProductControls.CapacityInGallons)) {
      this.formControls[ProductControls.CapacityInGallons] = new FormControl(
        data.capacity_in_gallons || 0,
        [
          Validators.required,
          Validators.pattern(decimalNumber),
        ]
      );
    }

    const media: IMediaPicker = {
      images: data.images?.map((i) => ({ ...i, state: 'original' })) || [],
      videos: data.videos?.map((i) => ({ ...i, state: 'original' })) || [],
    };
    console.log('media:', media);
    if (this.controls.includes(ProductControls.ProductMedia)) {
      this.formControls[ProductControls.ProductMedia] = new FormControl(
        media || {}
      );
    }

    if (this.controls.includes(ProductControls.ProductOptions)) {
      this.formControls[ProductControls.ProductOptions] = new FormControl(
        data.productOptions || [],
        [Validators.required]
      );
    }

    this.productForm = this.formBuilder.group(this.formControls);

    this.productForm.statusChanges.subscribe({
      next: (status) => {
        this.formIsValid.emit(status === 'VALID' && this.mediaPickerControl!.value.images?.filter(img => img.state !== 'delete')?.length > 0);
      },
    });
    this.productForm.valueChanges.subscribe((value: IProductResponseDto) => {
      const { productMedia, tags, ...info } = value as any;
      this.formChanged.emit({
        ...info,
        ...productMedia,
        tags: ((tags || []) as unknown as string[])?.join(','),
      });
    });
  }
  setupProductExpiry(data: { currentExpiry?: string }) {
    const productExpiryOptions: IProductExpiryOption[] = [
      {
        value: null,
        label: ProductExpiry.NoExpiry,
      },
      {
        value: this.productService.addDays(-1).toISOString(),
        label: ProductExpiry.Expired,
      },
      {
        value: this.productService.addDays(7).toISOString(),
        label: ProductExpiry.ExtendExpiry,
      },
    ];

    if (data.currentExpiry) {
      const expiryDate = data.currentExpiry;
      const isExpired = new Date(expiryDate) < new Date();

      this.productExpiryHintText = isExpired
        ? 'PRODUCT IS EXPIRED!'
        : this.getExpiryTextWithDate(expiryDate);
    }

    // Optionally set the updated options in your component's state or similar
    this.productExpiryOptions = productExpiryOptions;
  }

  onCategorySelectionChange(categoryId: number) {
    this.subcategoryIdControl!.patchValue(0);
    if (this.subsubcategoryIdControl) {
      this.subsubcategoryIdControl!.patchValue(0);
    }
    this.getSubCategories(categoryId);
  }

  slectSubCategory(subCategoryId: number) {
    console.log('click');
    if (this.subsubcategoryIdControl) {
      this.subsubcategoryIdControl!.patchValue(0);
    }
    this.getSubSubCategories(subCategoryId);
  }

  getCategories() {
    let type = 'other';
    if (this.categoryId === 5) {
      type = 'tank';
    } else if (this.categoryId === 1) {
      type = 'live';
    }
    this.auth
      .get(`/api/v1/get_parent_categories?type=${type}`)
      .subscribe((res: any) => {
        if (res.status === true) {
          this.categories = res.data;
        }
      });
  }

  getCountryList() {
    this.auth.get('/api/v1/countries').subscribe((res: any) => {
      if (res.status === true) {
        this.countries = res.data;
      }
    });
  }

  getSubCategories(categoryId: number) {
    this.auth
      .get('/api/v1/get_sub_categories?pid=' + categoryId)
      .subscribe((res: any) => {
        if (res.status === true) {
          this.subcategories = res.data;
        }
      });
  }
  getSubSubCategories(subCategoryId: number) {
    this.auth
      .get('/api/v1/get_sub_categories?pid=' + subCategoryId)
      .subscribe((res: any) => {
        if (res.status === true) {
          this.subsubcategories = res.data;
        }
      });
  }

  onExpirySelectionChange(expiryValue: string) {
    if (expiryValue) {
      this.productExpiryHintText = this.getExpiryTextWithDate(expiryValue);
    } else {
      this.productExpiryHintText = 'Hit save to remove expiry.';
    }
  }

  get productNameControl(): FormControl<string> | undefined {
    return this.formControls[ProductControls.ProductName];
  }

  get qtyControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.Qty];
  }

  get destinationCountryIdControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.DestinationCountryId];
  }

  get displayPriceControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.DisplayPrice];
  }

  get categoryIdControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.CategoryId];
  }

  get statusControl(): FormControl<string> | undefined {
    return this.formControls[ProductControls.Status];
  }

  get moreDescriptionControl(): FormControl<string> | undefined {
    return this.formControls[ProductControls.MoreDescription];
  }

  get subcategoryIdControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.SubcategoryId];
  }

  get subsubcategoryIdControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.SubsubcategoryId];
  }

  get isSeasonalControl(): FormControl<boolean> | undefined {
    return this.formControls[ProductControls.IsSeasonal];
  }

  get productExpiryControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.ProductExpiry];
  }

  get capacityInGallonsControl(): FormControl<number> | undefined {
    return this.formControls[ProductControls.CapacityInGallons];
  }

  get mediaPickerControl(): FormControl<IMediaPicker> | undefined {
    return this.formControls[ProductControls.ProductMedia];
  }

  get productOptionsControl(): FormControl<any[]> | undefined {
    return this.formControls[ProductControls.ProductOptions];
  }

  private getExpiryTextWithDate(date: string) {
    return `Expires on ${new Date(date).toLocaleDateString()} at 11:59PM`;
  }
}

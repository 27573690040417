import { NgClass } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductInputComponent } from '@components/product-input/product-input.component';
import { IApiResponse } from '@models/api-response.model';
import { IProductRequestDto } from '@models/product-list.model';
import {
  livestockInputControls,
  otherProductInputControls,
  tankInputControls,
} from '@pages/product/product-input/product-input-controls';
import { RequestService } from '@services/index';

@Component({
  selector: 'fh-product-input-page',
  standalone: true,
  imports: [ProductInputComponent, NgClass],
  templateUrl: './product-input-page.component.html',
  styleUrl: './product-input-page.component.scss',
})
export class ProductInputPageComponent implements OnInit {
  readonly categories = [
    { label: 'Livestock', id: 1 },
    { label: 'Tank', id: 5 },
    { label: 'Others', id: 0 },
  ];
  livestockControls = livestockInputControls;
  tankInputControls = tankInputControls;
  otherProductInputControls = otherProductInputControls;

  error = '';
  success = '';

  productInput = {
    data: signal<IProductRequestDto>({} as IProductRequestDto),
    valid: signal<boolean>(false),
  };
  userId?: string;
  productId?: number;

  categoryId = signal<number>(1);
  productCategoryId?: number;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem('aqua_userid')!;
    this.productId = parseInt(
      this.route.snapshot.paramMap.get('id') || '0',
      10
    );
  }

  getProductCategoryId(id: number) {
    this.productCategoryId = id;
  }

  saveProduct() {
    const data = this.productInput.data();

    const { images, videos, ...rest } = data;
    const requestBody = { ...rest } as any;

    requestBody['images'] = images.map((img) => {
      if (img.state === 'add') {
        return img;
      }
      delete img.base64;
      return img;
    });

    requestBody['videos'] = videos
      .filter((video) => video.state === 'delete')
      .map((video) => {
        delete video.base64;
        return video;
      });

    const saveVideos = (productId: string) => {
      const fd = new FormData();
      const videosToUpload = videos.filter((video) => video.state === 'add');

      return new Promise((resolve) => {
        if (videosToUpload.length > 0) {
          videosToUpload.forEach((video) => {
            fd.append(
              'video_' + new Date().getTime(),
              video.file as unknown as File
            );
          });
          fd.append('productId', productId);

          this.requestService
            .put('/api/v2/product-videos', fd)
            .subscribe((res: any) => {
              if (res.status === true) {
                resolve(true);
              }
            });
        } else {
          resolve(true);
        }
      });
    };

    data.vendor_id = Number(localStorage.getItem("aqua_userid"));

    console.log(requestBody);

    this.requestService
      .post<IApiResponse>(`/api/v2/product?productId=${this.productId}`, data)
      .subscribe(async (res) => {
        if (res.status == true) {
         saveVideos(res.data.id);
          this.router.navigate(['/products'], {
            state: { success_msg: 'Record saved successfully.' },
          });
        } else {
          this.error = res.message;
          setTimeout(() => {
            this.error = '';
          }, 3000);
        }
      });
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  show({
    message,
    type = 'info',
    duration = 2000,
  }: {
    message: string;
    type?: 'info' | 'danger';
    duration?: number;
  }) {
    let panelClass: string;
    switch (type) {
      case 'danger':
        panelClass = 'snack-bar-danger';
        break;
      case 'info':
      default:
        panelClass = 'snack-bar-info';
        break;
    }

    this.snackBar.open(message, 'Close', {
      duration,
      panelClass,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }
}

<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        @if (success_msg ) {
        <div class="alert alert-green">
          <span>{{ success_msg }}</span>
        </div>
        }

        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Product List</h4>
          </div>
          <div class="card-body">

            <mat-button-toggle-group
              name="fontStyle"
              aria-label="Font Style"
              (change)="onCategorySelection($event.value)"
              [value]="category_id"
            >
              <ng-container>
                <mat-button-toggle checked value="all"
                  >All({{ totalProductsAllCategories }})</mat-button-toggle
                >
              </ng-container>
              <ng-container>
                <mat-button-toggle value="expired">Expired </mat-button-toggle>
              </ng-container>
              @for (category of productListByCategoryId; track category) {
              <mat-button-toggle value="{{ category.id }}"
                >{{ category.category_name }}({{
                  category.quantity
                }})</mat-button-toggle
              >
              }
            </mat-button-toggle-group>
            <div class="mat-elevation-z8 overflow">
              <input
                matInput
                (keyup)="applyFilter($event)"
                placeholder="Ex. ium"
                #input
              />

              @if (totalProducts) {
              <table
                (matSortChange)="sortData($event)"
                mat-table
                matSort
                [dataSource]="dataSource"
              >
                <ng-container matColumnDef="id">
                  <th
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    ID
                  </th>
                  <td
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    {{ element.id }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="image">
                  <th
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Image
                  </th>
                  <td
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    @if ( element.product_image ) {
                    <img
                      src="{{ element.product_image }}"
                      width="80px;"
                      height="80px;"
                      alt="product image"
                    />
                    }
                  </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th
                    fxFlex="15"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Name
                  </th>
                  <td
                    fxFlex="15"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    {{ element.product_name }}
                  </td>
                </ng-container>
                <!-- MRP Column -->
              
                <!-- Sell Price Column -->
                <ng-container matColumnDef="displayPrice">
                  <th
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-sort-header="display_price"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Display Price
                  </th>
                  <td
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    {{ element.display_price | number : '1.2-2' }}
                  </td>
                </ng-container>
                <!-- quantity Column -->
                <ng-container matColumnDef="quantity">
                  <th
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-sort-header="qty"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Quantity
                  </th>
                  <td
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    {{ element.qty }}
                  </td>
                </ng-container>
                <!-- Delivery Column -->
                <ng-container matColumnDef="deliveryCharges">
                  <th
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Delivery Charges
                  </th>
                  <td
                    fxFlex="5"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    {{ element.delivery_standard_charges }}
                  </td>
                </ng-container>
                <!-- Subcategory Column -->
                <ng-container matColumnDef="subcategory">
                  <th
                    fxFlex="10"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Subcategory
                  </th>
                  <td
                    fxFlex="10"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    {{ element.subcategory_name }}
                  </td>
                </ng-container>
                <!-- Subcategory Column -->
                <ng-container matColumnDef="productExpiry">
                  <th
                    fxFlex="15"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Expiry
                  </th>
                  <td
                    fxFlex="15"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    @if (element.expires_at) {
                    <div
                      [ngClass]="
                        isProductExpired(element.expires_at)
                          ? 'product-expiry-status--expired'
                          : 'product-expiry-status--active'
                      "
                    >
                      {{ element.expires_at | date : 'dd/MMM/yy, h:mm a' }}
                      <span
                        matTooltip="Restart product expiry"
                        (click)="resetProductExpiry(element)"
                        class="material-icons expiry-restart-button"
                        tabindex="10"
                        (keydown.enter)="resetProductExpiry(element)"
                      >
                        restart_alt
                      </span>
                    </div>
                    }
                  </td>
                </ng-container>
                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th
                    fxFlex="10"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Action
                  </th>
                  <td
                    fxFlex="10"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                  >
                    <a [routerLink]="['/product_input/' + element.id]">
                      <span
                        _ngcontent-gxv-c19=""
                        class="material-icons icon-image-preview"
                        >visibility</span
                      >
                    </a>
                   &nbsp;
                    <a href="javascript:void(0);">
                      <span
                        _ngcontent-gxv-c19=""
                        class="material-icons icon-image-preview"
                        (click)="delete(element.id)"
                        tabindex="10"
                        (keydown.enter)="delete(element.id)"
                        >delete</span
                      >
                    </a>
                  </td>
                </ng-container>
                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th
                    fxFlex="10"
                    fxFlex.xs="20"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Status
                  </th>
                  <td
                    fxFlex="10"
                    fxFlex.xs="20"
                    mat-cell
                    *matCellDef="let element"
                    (click)="highlight(element.id)"
                  >
                    {{ element.status }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  [ngClass]="this.highlightedRow === row.id ? 'selected' : ''"
                ></tr>
              </table>
              } @if (!totalProducts) {
              <ng-container #noproducts>
                <p class="noproducts">No products available</p>
              </ng-container>
              } @if (totalProducts) {
              <mat-paginator
                #paginator
                [pageSizeOptions]="[15]"
                [length]="total"
                pageSize="15"
                showFirstLastButtons
                (page)="onPageChange($event)"
              ></mat-paginator>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DatePipe, DecimalPipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { environment } from "@environments/environment";
import { RequestService } from "@services/index";

@Component({
  selector: "fh-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.css"],
  standalone: true,
  imports: [DecimalPipe, DatePipe, FormsModule, MatButtonModule],
})
export class OrderDetailsComponent implements OnInit {
  list: any[] = [];
  app_url = environment.backendURL;
  orderId: string;
  orderDetails: any = {};
  constructor(
    private requestService: RequestService,
    private route: ActivatedRoute
  ) {
    this.orderId = this.route.snapshot.paramMap.get("id") as string;
  }

  ngOnInit(): void {
    this.fetchOrder();
  }

  fetchOrder() {
    this.requestService
      .get(`/api/v2/order-details/${this.orderId}`)
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          const {
            orderDetails: { details: itemList, ...rest },
          } = res.data;

          this.list = itemList;
          this.orderDetails = rest;
        }
      });
  }

  fetchDeliveryRemarks = async () => {
    this.requestService.get("/api/v1/delivery-remarks?orderId=" + this.orderId);
  };
}
